/*
 * This product includes color specifications and designs developed by Cynthia
 * Brewer (http://colorbrewer.org/).
 
 https://groups.google.com/forum/?fromgroups=#!topic/d3-js/iyXFgJR1JY0
 */

export const colors = {
  Burg: {
    "2": ["#ffc6c4", "#672044"],
    "3": ["#ffc6c4", "#cc607d", "#672044"],
    "4": ["#ffc6c4", "#e38191", "#ad466c", "#672044"],
    "5": ["#ffc6c4", "#ee919b", "#cc607d", "#9e3963", "#672044"],
    "6": ["#ffc6c4", "#f29ca3", "#da7489", "#b95073", "#93345d", "#672044"],
    "7": [
      "#ffc6c4",
      "#f4a3a8",
      "#e38191",
      "#cc607d",
      "#ad466c",
      "#8b3058",
      "#672044",
    ],
    tags: ["quantitative"],
  },
  BurgYl: {
    "2": ["#fbe6c5", "#70284a"],
    "3": ["#fbe6c5", "#dc7176", "#70284a"],
    "4": ["#fbe6c5", "#ee8a82", "#c8586c", "#70284a"],
    "5": ["#fbe6c5", "#f2a28a", "#dc7176", "#b24b65", "#70284a"],
    "6": ["#fbe6c5", "#f4b191", "#e7807d", "#d06270", "#a44360", "#70284a"],
    "7": [
      "#fbe6c5",
      "#f5ba98",
      "#ee8a82",
      "#dc7176",
      "#c8586c",
      "#9c3f5d",
      "#70284a",
    ],
    tags: ["quantitative"],
  },
  RedOr: {
    "2": ["#f6d2a9", "#b13f64"],
    "3": ["#f6d2a9", "#ea8171", "#b13f64"],
    "4": ["#f6d2a9", "#f19c7c", "#dd686c", "#b13f64"],
    "5": ["#f6d2a9", "#f3aa84", "#ea8171", "#d55d6a", "#b13f64"],
    "6": ["#f6d2a9", "#f4b28a", "#ef9177", "#e3726d", "#cf5669", "#b13f64"],
    "7": [
      "#f6d2a9",
      "#f5b78e",
      "#f19c7c",
      "#ea8171",
      "#dd686c",
      "#ca5268",
      "#b13f64",
    ],
    tags: ["quantitative"],
  },
  OrYel: {
    "2": ["#ecda9a", "#ee4d5a"],
    "3": ["#ecda9a", "#f7945d", "#ee4d5a"],
    "4": ["#ecda9a", "#f3ad6a", "#f97b57", "#ee4d5a"],
    "5": ["#ecda9a", "#f1b973", "#f7945d", "#f86f56", "#ee4d5a"],
    "6": ["#ecda9a", "#f0c079", "#f5a363", "#f98558", "#f76856", "#ee4d5a"],
    "7": [
      "#ecda9a",
      "#efc47e",
      "#f3ad6a",
      "#f7945d",
      "#f97b57",
      "#f66356",
      "#ee4d5a",
    ],
    tags: ["quantitative"],
  },
  Peach: {
    "2": ["#fde0c5", "#eb4a40"],
    "3": ["#fde0c5", "#f59e72", "#eb4a40"],
    "4": ["#fde0c5", "#f8b58b", "#f2855d", "#eb4a40"],
    "5": ["#fde0c5", "#f9c098", "#f59e72", "#f17854", "#eb4a40"],
    "6": ["#fde0c5", "#fac7a1", "#f7ac80", "#f38f65", "#f0704f", "#eb4a40"],
    "7": [
      "#fde0c5",
      "#facba6",
      "#f8b58b",
      "#59e72",
      "#f2855d",
      "#ef6a4c",
      "#eb4a40",
    ],
    tags: ["quantitative"],
  },
  PinkYl: {
    "2": ["#fef6b5", "#e15383"],
    "3": ["#fef6b5", "#ffa679", "#e15383"],
    "4": ["#fef6b5", "#ffc285", "#fa8a76", "#e15383"],
    "5": ["#fef6b5", "#ffd08e", "#ffa679", "#f67b77", "#e15383"],
    "6": ["#fef6b5", "#ffd795", "#ffb77f", "#fd9576", "#f37378", "#e15383"],
    "7": [
      "#fef6b5",
      "#ffdd9a",
      "#ffc285",
      "#ffa679",
      "#fa8a76",
      "#f16d7a",
      "#e15383",
    ],
    tags: ["quantitative"],
  },
  Mint: {
    "2": ["#e4f1e1", "#0d585f"],
    "3": ["#e4f1e1", "#63a6a0", "#0d585f"],
    "4": ["#e4f1e1", "#89c0b6", "#448c8a", "#0d585f"],
    "5": ["#E4F1E1", "#9CCDC1", "#63A6A0", "#337F7F", "#0D585F"],
    "6": ["#e4f1e1", "#abd4c7", "#7ab5ad", "#509693", "#2c7778", "#0d585f"],
    "7": [
      "#e4f1e1",
      "#b4d9cc",
      "#89c0b6",
      "#63a6a0",
      "#448c8a",
      "#287274",
      "#0d585f",
    ],
    tags: ["quantitative"],
  },
  BluGrn: {
    "2": ["#c4e6c3", "#1d4f60"],
    "3": ["#c4e6c3", "#4da284", "#1d4f60"],
    "4": ["#c4e6c3", "#6dbc90", "#36877a", "#1d4f60"],
    "5": ["#c4e6c3", "#80c799", "#4da284", "#2d7974", "#1d4f60"],
    "6": ["#c4e6c3", "#8dce9f", "#5fb28b", "#3e927e", "#297071", "#1d4f60"],
    "7": [
      "#c4e6c3",
      "#96d2a4",
      "#6dbc90",
      "#4da284",
      "#36877a",
      "#266b6e",
      "#1d4f60",
    ],
    tags: ["quantitative"],
  },
  DarkMint: {
    "2": ["#d2fbd4", "#123f5a"],
    "3": ["#d2fbd4", "#559c9e", "#123f5a"],
    "4": ["#d2fbd4", "#7bbcb0", "#3a7c89", "#123f5a"],
    "5": ["#d2fbd4", "#8eccb9", "#559c9e", "#2b6c7f", "#123f5a"],
    "6": ["#d2fbd4", "#9cd5be", "#6cafa9", "#458892", "#266377", "#123f5a"],
    "7": [
      "#d2fbd4",
      "#a5dbc2",
      "#7bbcb0",
      "#559c9e",
      "#3a7c89",
      "#235d72",
      "#123f5a",
    ],
    tags: ["quantitative"],
  },
  Emrld: {
    "2": ["#d3f2a3", "#074050"],
    "3": ["#d3f2a3", "#4c9b82", "#074050"],
    "4": ["#d3f2a3", "#6cc08b", "#217a79", "#074050"],
    "5": ["#d3f2a3", "#82d091", "#4c9b82", "#19696f", "#074050"],
    "6": ["#d3f2a3", "#8fda94", "#60b187", "#35877d", "#145f69", "#074050"],
    "7": [
      "#d3f2a3",
      "#97e196",
      "#6cc08b",
      "#4c9b82",
      "#217a79",
      "#105965",
      "#074050",
    ],
    tags: ["quantitative"],
  },
  ag_GrnYl: {
    "2": ["#245668", "#EDEF5D"],
    "3": ["#245668", "#39AB7E", "#EDEF5D"],
    "4": ["#245668", "#0D8F81", "#6EC574", "#EDEF5D"],
    "5": ["#245668", "#04817E", "#39AB7E", "#8BD16D", "#EDEF5D"],
    "6": ["#245668", "#09787C", "#1D9A81", "#58BB79", "#9DD869", "#EDEF5D"],
    "7": [
      "#245668",
      "#0F7279",
      "#0D8F81",
      "#39AB7E",
      "#6EC574",
      "#A9DC67",
      "#EDEF5D",
    ],
    tags: ["aggregation"],
  },
  BluYl: {
    "2": ["#f7feae", "#045275"],
    "3": ["#f7feae", "#46aea0", "#045275"],
    "4": ["#f7feae", "#7ccba2", "#089099", "#045275"],
    "5": ["#f7feae", "#9bd8a4", "#46aea0", "#058092", "#045275"],
    "6": ["#f7feae", "#ace1a4", "#68bfa1", "#2a9c9c", "#02778e", "#045275"],
    "7": [
      "#f7feae",
      "#b7e6a5",
      "#7ccba2",
      "#46aea0",
      "#089099",
      "#00718b",
      "#045275",
    ],
    tags: ["quantitative"],
  },
  Teal: {
    "2": ["#d1eeea", "#2a5674"],
    "3": ["#d1eeea", "#68abb8", "#2a5674"],
    "4": ["#d1eeea", "#85c4c9", "#4f90a6", "#2a5674"],
    "5": ["#d1eeea", "#96d0d1", "#68abb8", "#45829b", "#2a5674"],
    "6": ["#d1eeea", "#a1d7d6", "#79bbc3", "#599bae", "#3f7994", "#2a5674"],
    "7": [
      "#d1eeea",
      "#a8dbd9",
      "#85c4c9",
      "#68abb8",
      "#4f90a6",
      "#3b738f",
      "#2a5674",
    ],
    tags: ["quantitative"],
  },
  TealGrn: {
    "2": ["#b0f2bc", "#257d98"],
    "3": ["#b0f2bc", "#4cc8a3", "#257d98"],
    "4": ["#b0f2bc", "#67dba5", "#38b2a3", "#257d98"],
    "5": ["#b0f2bc", "#77e2a8", "#4cc8a3", "#31a6a2", "#257d98"],
    "6": ["#b0f2bc", "#82e6aa", "#5bd4a4", "#3fbba3", "#2e9ea1", "#257d98"],
    "7": [
      "#b0f2bc",
      "#89e8ac",
      "#67dba5",
      "#4cc8a3",
      "#38b2a3",
      "#2c98a0",
      "#257d98",
    ],
    tags: ["quantitative"],
  },
  Purp: {
    "2": ["#f3e0f7", "#63589f"],
    "3": ["#f3e0f7", "#b998dd", "#63589f"],
    "4": ["#f3e0f7", "#d1afe8", "#9f82ce", "#63589f"],
    "5": ["#f3e0f7", "#dbbaed", "#b998dd", "#9178c4", "#63589f"],
    "6": ["#f3e0f7", "#e0c2ef", "#c8a5e4", "#aa8bd4", "#8871be", "#63589f"],
    "7": [
      "#f3e0f7",
      "#e4c7f1",
      "#d1afe8",
      "#b998dd",
      "#9f82ce",
      "#826dba",
      "#63589f",
    ],
    tags: ["quantitative"],
  },
  PurpOr: {
    "2": ["#f9ddda", "#573b88"],
    "3": ["#f9ddda", "#ce78b3", "#573b88"],
    "4": ["#f9ddda", "#e597b9", "#ad5fad", "#573b88"],
    "5": ["#f9ddda", "#eda8bd", "#ce78b3", "#9955a8", "#573b88"],
    "6": ["#f9ddda", "#f0b2c1", "#dd8ab6", "#bb69b0", "#8c4fa4", "#573b88"],
    "7": [
      "#f9ddda",
      "#f2b9c4",
      "#e597b9",
      "#ce78b3",
      "#ad5fad",
      "#834ba0",
      "#573b88",
    ],
    tags: ["quantitative"],
  },
  Sunset: {
    "2": ["#f3e79b", "#5c53a5"],
    "3": ["#f3e79b", "#eb7f86", "#5c53a5"],
    "4": ["#f3e79b", "#f8a07e", "#ce6693", "#5c53a5"],
    "5": ["#f3e79b", "#fab27f", "#eb7f86", "#b95e9a", "#5c53a5"],
    "6": ["#f3e79b", "#fabc82", "#f59280", "#dc6f8e", "#ab5b9e", "#5c53a5"],
    "7": [
      "#f3e79b",
      "#fac484",
      "#f8a07e",
      "#eb7f86",
      "#ce6693",
      "#a059a0",
      "#5c53a5",
    ],
    tags: ["quantitative"],
  },
  Magenta: {
    "2": ["#f3cbd3", "#6c2167"],
    "3": ["#f3cbd3", "#ca699d", "#6c2167"],
    "4": ["#f3cbd3", "#dd88ac", "#b14d8e", "#6c2167"],
    "5": ["#f3cbd3", "#e498b4", "#ca699d", "#a24186", "#6c2167"],
    "6": ["#f3cbd3", "#e7a2b9", "#d67ba5", "#bc5894", "#983a81", "#6c2167"],
    "7": [
      "#f3cbd3",
      "#eaa9bd",
      "#dd88ac",
      "#ca699d",
      "#b14d8e",
      "#91357d",
      "#6c2167",
    ],
    tags: ["quantitative"],
  },
  SunsetDark: {
    "2": ["#fcde9c", "#7c1d6f"],
    "3": ["#fcde9c", "#e34f6f", "#7c1d6f"],
    "4": ["#fcde9c", "#f0746e", "#dc3977", "#7c1d6f"],
    "5": ["#fcde9c", "#f58670", "#e34f6f", "#d72d7c", "#7c1d6f"],
    "6": ["#fcde9c", "#f89872", "#ec666d", "#df4273", "#c5287b", "#7c1d6f"],
    "7": [
      "#fcde9c",
      "#faa476",
      "#f0746e",
      "#e34f6f",
      "#dc3977",
      "#b9257a",
      "#7c1d6f",
    ],
    tags: ["quantitative"],
  },
  ag_Sunset: {
    "2": ["#4b2991", "#edd9a3"],
    "3": ["#4b2991", "#ea4f88", "#edd9a3"],
    "4": ["#4b2991", "#c0369d", "#fa7876", "#edd9a3"],
    "5": ["#4b2991", "#a52fa2", "#ea4f88", "#fa9074", "#edd9a3"],
    "6": ["#4b2991", "#932da3", "#d43f96", "#f7667c", "#f89f77", "#edd9a3"],
    "7": [
      "#4b2991",
      "#872ca2",
      "#c0369d",
      "#ea4f88",
      "#fa7876",
      "#f6a97a",
      "#edd9a3",
    ],
    tags: ["aggregation"],
  },
  BrwnYl: {
    "2": ["#ede5cf", "#541f3f"],
    "3": ["#ede5cf", "#c1766f", "#541f3f"],
    "4": ["#ede5cf", "#d39c83", "#a65461", "#541f3f"],
    "5": ["#ede5cf", "#daaf91", "#c1766f", "#95455a", "#541f3f"],
    "6": ["#ede5cf", "#ddba9b", "#cd8c7a", "#b26166", "#8a3c56", "#541f3f"],
    "7": [
      "#ede5cf",
      "#e0c2a2",
      "#d39c83",
      "#c1766f",
      "#a65461",
      "#813753",
      "#541f3f",
    ],
    tags: ["quantitative"],
  },
  ArmyRose: {
    "2": ["#929b4f", "#db8195"],
    "3": ["#a3ad62", "#fdfbe4", "#df91a3"],
    "4": ["#929b4f", "#d9dbaf", "#f3d1ca", "#db8195"],
    "5": ["#879043", "#c1c68c", "#fdfbe4", "#ebb4b8", "#d8758b"],
    "6": ["#7f883b", "#b0b874", "#e3e4be", "#f6ddd1", "#e4a0ac", "#d66d85"],
    "7": [
      "#798234",
      "#a3ad62",
      "#d0d3a2",
      "#fdfbe4",
      "#f0c6c3",
      "#df91a3",
      "#d46780",
    ],
    tags: ["diverging"],
  },
  Fall: {
    "2": ["#3d5941", "#ca562c"],
    "3": ["#3d5941", "#f6edbd", "#ca562c"],
    "4": ["#3d5941", "#b5b991", "#edbb8a", "#ca562c"],
    "5": ["#3d5941", "#96a07c", "#f6edbd", "#e6a272", "#ca562c"],
    "6": ["#3d5941", "#839170", "#cecea2", "#f1cf9e", "#e19464", "#ca562c"],
    "7": [
      "#3d5941",
      "#778868",
      "#b5b991",
      "#f6edbd",
      "#edbb8a",
      "#de8a5a",
      "#ca562c",
    ],
    tags: ["diverging"],
  },
  Geyser: {
    "2": ["#008080", "#ca562c"],
    "3": ["#008080", "#f6edbd", "#ca562c"],
    "4": ["#008080", "#b4c8a8", "#edbb8a", "#ca562c"],
    "5": ["#008080", "#92b69e", "#f6edbd", "#e6a272", "#ca562c"],
    "6": ["#008080", "#7eab98", "#ced7b1", "#f1cf9e", "#e19464", "#ca562c"],
    "7": [
      "#008080",
      "#70a494",
      "#b4c8a8",
      "#f6edbd",
      "#edbb8a",
      "#de8a5a",
      "#ca562c",
    ],
    tags: ["diverging"],
  },
  Temps: {
    "2": ["#009392", "#cf597e"],
    "3": ["#009392", "#e9e29c", "#cf597e"],
    "4": ["#009392", "#9ccb86", "#eeb479", "#cf597e"],
    "5": ["#009392", "#71be83", "#e9e29c", "#ed9c72", "#cf597e"],
    "6": ["#009392", "#52b684", "#bcd48c", "#edc783", "#eb8d71", "#cf597e"],
    "7": [
      "#009392",
      "#39b185",
      "#9ccb86",
      "#e9e29c",
      "#eeb479",
      "#e88471",
      "#cf597e",
    ],
    tags: ["diverging"],
  },
  TealRose: {
    "2": ["#009392", "#d0587e"],
    "3": ["#009392", "#f1eac8", "#d0587e"],
    "4": ["#009392", "#91b8aa", "#f1eac8", "#dfa0a0", "#d0587e"],
    "5": ["#009392", "#91b8aa", "#f1eac8", "#dfa0a0", "#d0587e"],
    "6": ["#009392", "#72aaa1", "#b1c7b3", "#e5b9ad", "#d98994", "#d0587e"],
    "7": [
      "#009392",
      "#72aaa1",
      "#b1c7b3",
      "#f1eac8",
      "#e5b9ad",
      "#d98994",
      "#d0587e",
    ],
    tags: ["diverging"],
  },
  Tropic: {
    "2": ["#009B9E", "#C75DAB"],
    "3": ["#009B9E", "#F1F1F1", "#C75DAB"],
    "4": ["#009B9E", "#A7D3D4", "#E4C1D9", "#C75DAB"],
    "5": ["#009B9E", "#7CC5C6", "#F1F1F1", "#DDA9CD", "#C75DAB"],
    "6": ["#009B9E", "#5DBCBE", "#C6DFDF", "#E9D4E2", "#D99BC6", "#C75DAB"],
    "7": [
      "#009B9E",
      "#42B7B9",
      "#A7D3D4",
      "#F1F1F1",
      "#E4C1D9",
      "#D691C1",
      "#C75DAB",
    ],
    tags: ["diverging"],
  },
  Earth: {
    "2": ["#A16928", "#2887a1"],
    "3": ["#A16928", "#edeac2", "#2887a1"],
    "4": ["#A16928", "#d6bd8d", "#b5c8b8", "#2887a1"],
    "5": ["#A16928", "#caa873", "#edeac2", "#98b7b2", "#2887a1"],
    "6": ["#A16928", "#c29b64", "#e0cfa2", "#cbd5bc", "#85adaf", "#2887a1"],
    "7": [
      "#A16928",
      "#bd925a",
      "#d6bd8d",
      "#edeac2",
      "#b5c8b8",
      "#79a7ac",
      "#2887a1",
    ],
    tags: ["diverging"],
  },
  Antique: {
    "2": ["#855C75", "#D9AF6B", "#7C7C7C"],
    "3": ["#855C75", "#D9AF6B", "#AF6458", "#7C7C7C"],
    "4": ["#855C75", "#D9AF6B", "#AF6458", "#736F4C", "#7C7C7C"],
    "5": ["#855C75", "#D9AF6B", "#AF6458", "#736F4C", "#526A83", "#7C7C7C"],
    "6": [
      "#855C75",
      "#D9AF6B",
      "#AF6458",
      "#736F4C",
      "#526A83",
      "#625377",
      "#7C7C7C",
    ],
    "7": [
      "#855C75",
      "#D9AF6B",
      "#AF6458",
      "#736F4C",
      "#526A83",
      "#625377",
      "#68855C",
      "#7C7C7C",
    ],
    "8": [
      "#855C75",
      "#D9AF6B",
      "#AF6458",
      "#736F4C",
      "#526A83",
      "#625377",
      "#68855C",
      "#9C9C5E",
      "#7C7C7C",
    ],
    "9": [
      "#855C75",
      "#D9AF6B",
      "#AF6458",
      "#736F4C",
      "#526A83",
      "#625377",
      "#68855C",
      "#9C9C5E",
      "#A06177",
      "#7C7C7C",
    ],
    "10": [
      "#855C75",
      "#D9AF6B",
      "#AF6458",
      "#736F4C",
      "#526A83",
      "#625377",
      "#68855C",
      "#9C9C5E",
      "#A06177",
      "#8C785D",
      "#7C7C7C",
    ],
    "11": [
      "#855C75",
      "#D9AF6B",
      "#AF6458",
      "#736F4C",
      "#526A83",
      "#625377",
      "#68855C",
      "#9C9C5E",
      "#A06177",
      "#8C785D",
      "#467378",
      "#7C7C7C",
    ],
    tags: ["qualitative"],
  },
  Bold: {
    "2": ["#7F3C8D", "#11A579", "#A5AA99"],
    "3": ["#7F3C8D", "#11A579", "#3969AC", "#A5AA99"],
    "4": ["#7F3C8D", "#11A579", "#3969AC", "#F2B701", "#A5AA99"],
    "5": ["#7F3C8D", "#11A579", "#3969AC", "#F2B701", "#E73F74", "#A5AA99"],
    "6": [
      "#7F3C8D",
      "#11A579",
      "#3969AC",
      "#F2B701",
      "#E73F74",
      "#80BA5A",
      "#A5AA99",
    ],
    "7": [
      "#7F3C8D",
      "#11A579",
      "#3969AC",
      "#F2B701",
      "#E73F74",
      "#80BA5A",
      "#E68310",
      "#A5AA99",
    ],
    "8": [
      "#7F3C8D",
      "#11A579",
      "#3969AC",
      "#F2B701",
      "#E73F74",
      "#80BA5A",
      "#E68310",
      "#008695",
      "#A5AA99",
    ],
    "9": [
      "#7F3C8D",
      "#11A579",
      "#3969AC",
      "#F2B701",
      "#E73F74",
      "#80BA5A",
      "#E68310",
      "#008695",
      "#CF1C90",
      "#A5AA99",
    ],
    "10": [
      "#7F3C8D",
      "#11A579",
      "#3969AC",
      "#F2B701",
      "#E73F74",
      "#80BA5A",
      "#E68310",
      "#008695",
      "#CF1C90",
      "#f97b72",
      "#A5AA99",
    ],
    "11": [
      "#7F3C8D",
      "#11A579",
      "#3969AC",
      "#F2B701",
      "#E73F74",
      "#80BA5A",
      "#E68310",
      "#008695",
      "#CF1C90",
      "#f97b72",
      "#4b4b8f",
      "#A5AA99",
    ],
    tags: ["qualitative"],
  },
  Pastel: {
    "2": ["#66C5CC", "#F6CF71", "#B3B3B3"],
    "3": ["#66C5CC", "#F6CF71", "#F89C74", "#B3B3B3"],
    "4": ["#66C5CC", "#F6CF71", "#F89C74", "#DCB0F2", "#B3B3B3"],
    "5": ["#66C5CC", "#F6CF71", "#F89C74", "#DCB0F2", "#87C55F", "#B3B3B3"],
    "6": [
      "#66C5CC",
      "#F6CF71",
      "#F89C74",
      "#DCB0F2",
      "#87C55F",
      "#9EB9F3",
      "#B3B3B3",
    ],
    "7": [
      "#66C5CC",
      "#F6CF71",
      "#F89C74",
      "#DCB0F2",
      "#87C55F",
      "#9EB9F3",
      "#FE88B1",
      "#B3B3B3",
    ],
    "8": [
      "#66C5CC",
      "#F6CF71",
      "#F89C74",
      "#DCB0F2",
      "#87C55F",
      "#9EB9F3",
      "#FE88B1",
      "#C9DB74",
      "#B3B3B3",
    ],
    "9": [
      "#66C5CC",
      "#F6CF71",
      "#F89C74",
      "#DCB0F2",
      "#87C55F",
      "#9EB9F3",
      "#FE88B1",
      "#C9DB74",
      "#8BE0A4",
      "#B3B3B3",
    ],
    "10": [
      "#66C5CC",
      "#F6CF71",
      "#F89C74",
      "#DCB0F2",
      "#87C55F",
      "#9EB9F3",
      "#FE88B1",
      "#C9DB74",
      "#8BE0A4",
      "#B497E7",
      "#B3B3B3",
    ],
    "11": [
      "#66C5CC",
      "#F6CF71",
      "#F89C74",
      "#DCB0F2",
      "#87C55F",
      "#9EB9F3",
      "#FE88B1",
      "#C9DB74",
      "#8BE0A4",
      "#B497E7",
      "#D3B484",
      "#B3B3B3",
    ],
    tags: ["qualitative"],
  },
  Prism: {
    "2": ["#5F4690", "#1D6996", "#666666"],
    "3": ["#5F4690", "#1D6996", "#38A6A5", "#666666"],
    "4": ["#5F4690", "#1D6996", "#38A6A5", "#0F8554", "#666666"],
    "5": ["#5F4690", "#1D6996", "#38A6A5", "#0F8554", "#73AF48", "#666666"],
    "6": [
      "#5F4690",
      "#1D6996",
      "#38A6A5",
      "#0F8554",
      "#73AF48",
      "#EDAD08",
      "#666666",
    ],
    "7": [
      "#5F4690",
      "#1D6996",
      "#38A6A5",
      "#0F8554",
      "#73AF48",
      "#EDAD08",
      "#E17C05",
      "#666666",
    ],
    "8": [
      "#5F4690",
      "#1D6996",
      "#38A6A5",
      "#0F8554",
      "#73AF48",
      "#EDAD08",
      "#E17C05",
      "#CC503E",
      "#666666",
    ],
    "9": [
      "#5F4690",
      "#1D6996",
      "#38A6A5",
      "#0F8554",
      "#73AF48",
      "#EDAD08",
      "#E17C05",
      "#CC503E",
      "#94346E",
      "#666666",
    ],
    "10": [
      "#5F4690",
      "#1D6996",
      "#38A6A5",
      "#0F8554",
      "#73AF48",
      "#EDAD08",
      "#E17C05",
      "#CC503E",
      "#94346E",
      "#6F4070",
      "#666666",
    ],
    "11": [
      "#5F4690",
      "#1D6996",
      "#38A6A5",
      "#0F8554",
      "#73AF48",
      "#EDAD08",
      "#E17C05",
      "#CC503E",
      "#94346E",
      "#6F4070",
      "#994E95",
      "#666666",
    ],
    tags: ["qualitative"],
  },
  Safe: {
    "2": ["#88CCEE", "#CC6677", "#888888"],
    "3": ["#88CCEE", "#CC6677", "#DDCC77", "#888888"],
    "4": ["#88CCEE", "#CC6677", "#DDCC77", "#117733", "#888888"],
    "5": ["#88CCEE", "#CC6677", "#DDCC77", "#117733", "#332288", "#888888"],
    "6": [
      "#88CCEE",
      "#CC6677",
      "#DDCC77",
      "#117733",
      "#332288",
      "#AA4499",
      "#888888",
    ],
    "7": [
      "#88CCEE",
      "#CC6677",
      "#DDCC77",
      "#117733",
      "#332288",
      "#AA4499",
      "#44AA99",
      "#888888",
    ],
    "8": [
      "#88CCEE",
      "#CC6677",
      "#DDCC77",
      "#117733",
      "#332288",
      "#AA4499",
      "#44AA99",
      "#999933",
      "#888888",
    ],
    "9": [
      "#88CCEE",
      "#CC6677",
      "#DDCC77",
      "#117733",
      "#332288",
      "#AA4499",
      "#44AA99",
      "#999933",
      "#882255",
      "#888888",
    ],
    "10": [
      "#88CCEE",
      "#CC6677",
      "#DDCC77",
      "#117733",
      "#332288",
      "#AA4499",
      "#44AA99",
      "#999933",
      "#882255",
      "#661100",
      "#888888",
    ],
    "11": [
      "#88CCEE",
      "#CC6677",
      "#DDCC77",
      "#117733",
      "#332288",
      "#AA4499",
      "#44AA99",
      "#999933",
      "#882255",
      "#661100",
      "#6699CC",
      "#888888",
    ],
    tags: ["qualitative", "colorblind"],
  },
  Vivid: {
    "2": ["#E58606", "#5D69B1", "#A5AA99"],
    "3": ["#E58606", "#5D69B1", "#52BCA3", "#A5AA99"],
    "4": ["#E58606", "#5D69B1", "#52BCA3", "#99C945", "#A5AA99"],
    "5": ["#E58606", "#5D69B1", "#52BCA3", "#99C945", "#CC61B0", "#A5AA99"],
    "6": [
      "#E58606",
      "#5D69B1",
      "#52BCA3",
      "#99C945",
      "#CC61B0",
      "#24796C",
      "#A5AA99",
    ],
    "7": [
      "#E58606",
      "#5D69B1",
      "#52BCA3",
      "#99C945",
      "#CC61B0",
      "#24796C",
      "#DAA51B",
      "#A5AA99",
    ],
    "8": [
      "#E58606",
      "#5D69B1",
      "#52BCA3",
      "#99C945",
      "#CC61B0",
      "#24796C",
      "#DAA51B",
      "#2F8AC4",
      "#A5AA99",
    ],
    "9": [
      "#E58606",
      "#5D69B1",
      "#52BCA3",
      "#99C945",
      "#CC61B0",
      "#24796C",
      "#DAA51B",
      "#2F8AC4",
      "#764E9F",
      "#A5AA99",
    ],
    "10": [
      "#E58606",
      "#5D69B1",
      "#52BCA3",
      "#99C945",
      "#CC61B0",
      "#24796C",
      "#DAA51B",
      "#2F8AC4",
      "#764E9F",
      "#ED645A",
      "#A5AA99",
    ],
    "11": [
      "#E58606",
      "#5D69B1",
      "#52BCA3",
      "#99C945",
      "#CC61B0",
      "#24796C",
      "#DAA51B",
      "#2F8AC4",
      "#764E9F",
      "#ED645A",
      "#CC3A8E",
      "#A5AA99",
    ],
    tags: ["qualitative"],
  },

  /*** Diverging ***/
  Spectral: {
    3: [
      [252, 141, 89],
      [255, 255, 191],
      [153, 213, 148],
    ],
    4: [
      [215, 25, 28],
      [253, 174, 97],
      [171, 221, 164],
      [43, 131, 186],
    ],
    5: [
      [215, 25, 28],
      [253, 174, 97],
      [255, 255, 191],
      [171, 221, 164],
      [43, 131, 186],
    ],
    6: [
      [213, 62, 79],
      [252, 141, 89],
      [254, 224, 139],
      [230, 245, 152],
      [153, 213, 148],
      [50, 136, 189],
    ],
    7: [
      [213, 62, 79],
      [252, 141, 89],
      [254, 224, 139],
      [255, 255, 191],
      [230, 245, 152],
      [153, 213, 148],
      [50, 136, 189],
    ],
    8: [
      [213, 62, 79],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 139],
      [230, 245, 152],
      [171, 221, 164],
      [102, 194, 165],
      [50, 136, 189],
    ],
    9: [
      [213, 62, 79],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 139],
      [255, 255, 191],
      [230, 245, 152],
      [171, 221, 164],
      [102, 194, 165],
      [50, 136, 189],
    ],
    10: [
      [158, 1, 66],
      [213, 62, 79],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 139],
      [230, 245, 152],
      [171, 221, 164],
      [102, 194, 165],
      [50, 136, 189],
      [94, 79, 162],
    ],
    11: [
      [158, 1, 66],
      [213, 62, 79],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 139],
      [255, 255, 191],
      [230, 245, 152],
      [171, 221, 164],
      [102, 194, 165],
      [50, 136, 189],
      [94, 79, 162],
    ],
    properties: {
      type: "div",
      blind: [2, 2, 2, 0, 0, 0, 0, 0, 0],
      print: [1, 1, 1, 0, 0, 0, 0, 0, 0],
      copy: [1, 1, 1, 0, 0, 0, 0, 0, 0],
      screen: [1, 1, 2, 0, 0, 0, 0, 0, 0],
    },
  },
  RdYlGn: {
    3: [
      [252, 141, 89],
      [255, 255, 191],
      [145, 207, 96],
    ],
    4: [
      [215, 25, 28],
      [253, 174, 97],
      [166, 217, 106],
      [26, 150, 65],
    ],
    5: [
      [215, 25, 28],
      [253, 174, 97],
      [255, 255, 191],
      [166, 217, 106],
      [26, 150, 65],
    ],
    6: [
      [215, 48, 39],
      [252, 141, 89],
      [254, 224, 139],
      [217, 239, 139],
      [145, 207, 96],
      [26, 152, 80],
    ],
    7: [
      [215, 48, 39],
      [252, 141, 89],
      [254, 224, 139],
      [255, 255, 191],
      [217, 239, 139],
      [145, 207, 96],
      [26, 152, 80],
    ],
    8: [
      [215, 48, 39],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 139],
      [217, 239, 139],
      [166, 217, 106],
      [102, 189, 99],
      [26, 152, 80],
    ],
    9: [
      [215, 48, 39],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 139],
      [255, 255, 191],
      [217, 239, 139],
      [166, 217, 106],
      [102, 189, 99],
      [26, 152, 80],
    ],
    10: [
      [165, 0, 38],
      [215, 48, 39],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 139],
      [217, 239, 139],
      [166, 217, 106],
      [102, 189, 99],
      [26, 152, 80],
      [0, 104, 55],
    ],
    11: [
      [165, 0, 38],
      [215, 48, 39],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 139],
      [255, 255, 191],
      [217, 239, 139],
      [166, 217, 106],
      [102, 189, 99],
      [26, 152, 80],
      [0, 104, 55],
    ],
    properties: {
      type: "div",
      blind: [2, 2, 2, 0, 0, 0, 0, 0, 0],
      print: [1, 1, 1, 2, 0, 0, 0, 0, 0],
      copy: [0],
      screen: [1, 1, 1, 0, 0, 0, 0, 0, 0],
    },
  },
  RdBu: {
    3: [
      [239, 138, 98],
      [247, 247, 247],
      [103, 169, 207],
    ],
    4: [
      [202, 0, 32],
      [244, 165, 130],
      [146, 197, 222],
      [5, 113, 176],
    ],
    5: [
      [202, 0, 32],
      [244, 165, 130],
      [247, 247, 247],
      [146, 197, 222],
      [5, 113, 176],
    ],
    6: [
      [178, 24, 43],
      [239, 138, 98],
      [253, 219, 199],
      [209, 229, 240],
      [103, 169, 207],
      [33, 102, 172],
    ],
    7: [
      [178, 24, 43],
      [239, 138, 98],
      [253, 219, 199],
      [247, 247, 247],
      [209, 229, 240],
      [103, 169, 207],
      [33, 102, 172],
    ],
    8: [
      [178, 24, 43],
      [214, 96, 77],
      [244, 165, 130],
      [253, 219, 199],
      [209, 229, 240],
      [146, 197, 222],
      [67, 147, 195],
      [33, 102, 172],
    ],
    9: [
      [178, 24, 43],
      [214, 96, 77],
      [244, 165, 130],
      [253, 219, 199],
      [247, 247, 247],
      [209, 229, 240],
      [146, 197, 222],
      [67, 147, 195],
      [33, 102, 172],
    ],
    10: [
      [103, 0, 31],
      [178, 24, 43],
      [214, 96, 77],
      [244, 165, 130],
      [253, 219, 199],
      [209, 229, 240],
      [146, 197, 222],
      [67, 147, 195],
      [33, 102, 172],
      [5, 48, 97],
    ],
    11: [
      [103, 0, 31],
      [178, 24, 43],
      [214, 96, 77],
      [244, 165, 130],
      [253, 219, 199],
      [247, 247, 247],
      [209, 229, 240],
      [146, 197, 222],
      [67, 147, 195],
      [33, 102, 172],
      [5, 48, 97],
    ],
    properties: {
      type: "div",
      blind: [1],
      print: [1, 1, 1, 1, 0, 0, 0, 0, 0],
      copy: [0],
      screen: [1, 1, 1, 0, 0, 0, 0, 0, 0],
    },
  },
  PiYG: {
    3: [
      [233, 163, 201],
      [247, 247, 247],
      [161, 215, 106],
    ],
    4: [
      [208, 28, 139],
      [241, 182, 218],
      [184, 225, 134],
      [77, 172, 38],
    ],
    5: [
      [208, 28, 139],
      [241, 182, 218],
      [247, 247, 247],
      [184, 225, 134],
      [77, 172, 38],
    ],
    6: [
      [197, 27, 125],
      [233, 163, 201],
      [253, 224, 239],
      [230, 245, 208],
      [161, 215, 106],
      [77, 146, 33],
    ],
    7: [
      [197, 27, 125],
      [233, 163, 201],
      [253, 224, 239],
      [247, 247, 247],
      [230, 245, 208],
      [161, 215, 106],
      [77, 146, 33],
    ],
    8: [
      [197, 27, 125],
      [222, 119, 174],
      [241, 182, 218],
      [253, 224, 239],
      [230, 245, 208],
      [184, 225, 134],
      [127, 188, 65],
      [77, 146, 33],
    ],
    9: [
      [197, 27, 125],
      [222, 119, 174],
      [241, 182, 218],
      [253, 224, 239],
      [247, 247, 247],
      [230, 245, 208],
      [184, 225, 134],
      [127, 188, 65],
      [77, 146, 33],
    ],
    10: [
      [142, 1, 82],
      [197, 27, 125],
      [222, 119, 174],
      [241, 182, 218],
      [253, 224, 239],
      [230, 245, 208],
      [184, 225, 134],
      [127, 188, 65],
      [77, 146, 33],
      [39, 100, 25],
    ],
    11: [
      [142, 1, 82],
      [197, 27, 125],
      [222, 119, 174],
      [241, 182, 218],
      [253, 224, 239],
      [247, 247, 247],
      [230, 245, 208],
      [184, 225, 134],
      [127, 188, 65],
      [77, 146, 33],
      [39, 100, 25],
    ],
    properties: {
      type: "div",
      blind: [1],
      print: [1, 1, 2, 0, 0, 0, 0, 0, 0],
      copy: [0],
      screen: [1, 1, 2, 0, 0, 0, 0, 0, 0],
    },
  },
  PRGn: {
    3: [
      [175, 141, 195],
      [247, 247, 247],
      [127, 191, 123],
    ],
    4: [
      [123, 50, 148],
      [194, 165, 207],
      [166, 219, 160],
      [0, 136, 55],
    ],
    5: [
      [123, 50, 148],
      [194, 165, 207],
      [247, 247, 247],
      [166, 219, 160],
      [0, 136, 55],
    ],
    6: [
      [118, 42, 131],
      [175, 141, 195],
      [231, 212, 232],
      [217, 240, 211],
      [127, 191, 123],
      [27, 120, 55],
    ],
    7: [
      [118, 42, 131],
      [175, 141, 195],
      [231, 212, 232],
      [247, 247, 247],
      [217, 240, 211],
      [127, 191, 123],
      [27, 120, 55],
    ],
    8: [
      [118, 42, 131],
      [153, 112, 171],
      [194, 165, 207],
      [231, 212, 232],
      [217, 240, 211],
      [166, 219, 160],
      [90, 174, 97],
      [27, 120, 55],
    ],
    9: [
      [118, 42, 131],
      [153, 112, 171],
      [194, 165, 207],
      [231, 212, 232],
      [247, 247, 247],
      [217, 240, 211],
      [166, 219, 160],
      [90, 174, 97],
      [27, 120, 55],
    ],
    10: [
      [64, 0, 75],
      [118, 42, 131],
      [153, 112, 171],
      [194, 165, 207],
      [231, 212, 232],
      [217, 240, 211],
      [166, 219, 160],
      [90, 174, 97],
      [27, 120, 55],
      [0, 68, 27],
    ],
    11: [
      [64, 0, 75],
      [118, 42, 131],
      [153, 112, 171],
      [194, 165, 207],
      [231, 212, 232],
      [247, 247, 247],
      [217, 240, 211],
      [166, 219, 160],
      [90, 174, 97],
      [27, 120, 55],
      [0, 68, 27],
    ],
    properties: {
      type: "div",
      blind: [1],
      print: [1, 1, 1, 1, 0, 0, 0, 0, 0],
      copy: [0],
      screen: [1, 1, 2, 2, 0, 0, 0, 0, 0],
    },
  },
  RdYlBu: {
    3: [
      [252, 141, 89],
      [255, 255, 191],
      [145, 191, 219],
    ],
    4: [
      [215, 25, 28],
      [253, 174, 97],
      [171, 217, 233],
      [44, 123, 182],
    ],
    5: [
      [215, 25, 28],
      [253, 174, 97],
      [255, 255, 191],
      [171, 217, 233],
      [44, 123, 182],
    ],
    6: [
      [215, 48, 39],
      [252, 141, 89],
      [254, 224, 144],
      [224, 243, 248],
      [145, 191, 219],
      [69, 117, 180],
    ],
    7: [
      [215, 48, 39],
      [252, 141, 89],
      [254, 224, 144],
      [255, 255, 191],
      [224, 243, 248],
      [145, 191, 219],
      [69, 117, 180],
    ],
    8: [
      [215, 48, 39],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 144],
      [224, 243, 248],
      [171, 217, 233],
      [116, 173, 209],
      [69, 117, 180],
    ],
    9: [
      [215, 48, 39],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 144],
      [255, 255, 191],
      [224, 243, 248],
      [171, 217, 233],
      [116, 173, 209],
      [69, 117, 180],
    ],
    10: [
      [165, 0, 38],
      [215, 48, 39],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 144],
      [224, 243, 248],
      [171, 217, 233],
      [116, 173, 209],
      [69, 117, 180],
      [49, 54, 149],
    ],
    11: [
      [165, 0, 38],
      [215, 48, 39],
      [244, 109, 67],
      [253, 174, 97],
      [254, 224, 144],
      [255, 255, 191],
      [224, 243, 248],
      [171, 217, 233],
      [116, 173, 209],
      [69, 117, 180],
      [49, 54, 149],
    ],
    properties: {
      type: "div",
      blind: [1],
      print: [1, 1, 1, 1, 2, 0, 0, 0, 0],
      copy: [0],
      screen: [1, 1, 1, 2, 0, 0, 0, 0, 0],
    },
  },
  BrBG: {
    3: [
      [216, 179, 101],
      [245, 245, 245],
      [90, 180, 172],
    ],
    4: [
      [166, 97, 26],
      [223, 194, 125],
      [128, 205, 193],
      [1, 133, 113],
    ],
    5: [
      [166, 97, 26],
      [223, 194, 125],
      [245, 245, 245],
      [128, 205, 193],
      [1, 133, 113],
    ],
    6: [
      [140, 81, 10],
      [216, 179, 101],
      [246, 232, 195],
      [199, 234, 229],
      [90, 180, 172],
      [1, 102, 94],
    ],
    7: [
      [140, 81, 10],
      [216, 179, 101],
      [246, 232, 195],
      [245, 245, 245],
      [199, 234, 229],
      [90, 180, 172],
      [1, 102, 94],
    ],
    8: [
      [140, 81, 10],
      [191, 129, 45],
      [223, 194, 125],
      [246, 232, 195],
      [199, 234, 229],
      [128, 205, 193],
      [53, 151, 143],
      [1, 102, 94],
    ],
    9: [
      [140, 81, 10],
      [191, 129, 45],
      [223, 194, 125],
      [246, 232, 195],
      [245, 245, 245],
      [199, 234, 229],
      [128, 205, 193],
      [53, 151, 143],
      [1, 102, 94],
    ],
    10: [
      [84, 48, 5],
      [140, 81, 10],
      [191, 129, 45],
      [223, 194, 125],
      [246, 232, 195],
      [199, 234, 229],
      [128, 205, 193],
      [53, 151, 143],
      [1, 102, 94],
      [0, 60, 48],
    ],
    11: [
      [84, 48, 5],
      [140, 81, 10],
      [191, 129, 45],
      [223, 194, 125],
      [246, 232, 195],
      [245, 245, 245],
      [199, 234, 229],
      [128, 205, 193],
      [53, 151, 143],
      [1, 102, 94],
      [0, 60, 48],
    ],
    properties: {
      type: "div",
      blind: [1],
      print: [1, 1, 1, 1, 0, 0, 0, 0, 0],
      copy: [0],
      screen: [1, 1, 1, 1, 0, 0, 0, 0, 0],
    },
  },
  RdGy: {
    3: [
      [239, 138, 98],
      [255, 255, 255],
      [153, 153, 153],
    ],
    4: [
      [202, 0, 32],
      [244, 165, 130],
      [186, 186, 186],
      [64, 64, 64],
    ],
    5: [
      [202, 0, 32],
      [244, 165, 130],
      [255, 255, 255],
      [186, 186, 186],
      [64, 64, 64],
    ],
    6: [
      [178, 24, 43],
      [239, 138, 98],
      [253, 219, 199],
      [224, 224, 224],
      [153, 153, 153],
      [77, 77, 77],
    ],
    7: [
      [178, 24, 43],
      [239, 138, 98],
      [253, 219, 199],
      [255, 255, 255],
      [224, 224, 224],
      [153, 153, 153],
      [77, 77, 77],
    ],
    8: [
      [178, 24, 43],
      [214, 96, 77],
      [244, 165, 130],
      [253, 219, 199],
      [224, 224, 224],
      [186, 186, 186],
      [135, 135, 135],
      [77, 77, 77],
    ],
    9: [
      [178, 24, 43],
      [214, 96, 77],
      [244, 165, 130],
      [253, 219, 199],
      [255, 255, 255],
      [224, 224, 224],
      [186, 186, 186],
      [135, 135, 135],
      [77, 77, 77],
    ],
    10: [
      [103, 0, 31],
      [178, 24, 43],
      [214, 96, 77],
      [244, 165, 130],
      [253, 219, 199],
      [224, 224, 224],
      [186, 186, 186],
      [135, 135, 135],
      [77, 77, 77],
      [26, 26, 26],
    ],
    11: [
      [103, 0, 31],
      [178, 24, 43],
      [214, 96, 77],
      [244, 165, 130],
      [253, 219, 199],
      [255, 255, 255],
      [224, 224, 224],
      [186, 186, 186],
      [135, 135, 135],
      [77, 77, 77],
      [26, 26, 26],
    ],
    properties: {
      type: "div",
      blind: [2],
      print: [1, 1, 1, 2, 0, 0, 0, 0, 0],
      copy: [0],
      screen: [1, 1, 2, 0, 0, 0, 0, 0, 0],
    },
  },
  PuOr: {
    3: [
      [241, 163, 64],
      [247, 247, 247],
      [153, 142, 195],
    ],
    4: [
      [230, 97, 1],
      [253, 184, 99],
      [178, 171, 210],
      [94, 60, 153],
    ],
    5: [
      [230, 97, 1],
      [253, 184, 99],
      [247, 247, 247],
      [178, 171, 210],
      [94, 60, 153],
    ],
    6: [
      [179, 88, 6],
      [241, 163, 64],
      [254, 224, 182],
      [216, 218, 235],
      [153, 142, 195],
      [84, 39, 136],
    ],
    7: [
      [179, 88, 6],
      [241, 163, 64],
      [254, 224, 182],
      [247, 247, 247],
      [216, 218, 235],
      [153, 142, 195],
      [84, 39, 136],
    ],
    8: [
      [179, 88, 6],
      [224, 130, 20],
      [253, 184, 99],
      [254, 224, 182],
      [216, 218, 235],
      [178, 171, 210],
      [128, 115, 172],
      [84, 39, 136],
    ],
    9: [
      [179, 88, 6],
      [224, 130, 20],
      [253, 184, 99],
      [254, 224, 182],
      [247, 247, 247],
      [216, 218, 235],
      [178, 171, 210],
      [128, 115, 172],
      [84, 39, 136],
    ],
    10: [
      [127, 59, 8],
      [179, 88, 6],
      [224, 130, 20],
      [253, 184, 99],
      [254, 224, 182],
      [216, 218, 235],
      [178, 171, 210],
      [128, 115, 172],
      [84, 39, 136],
      [45, 0, 75],
    ],
    11: [
      [127, 59, 8],
      [179, 88, 6],
      [224, 130, 20],
      [253, 184, 99],
      [254, 224, 182],
      [247, 247, 247],
      [216, 218, 235],
      [178, 171, 210],
      [128, 115, 172],
      [84, 39, 136],
      [45, 0, 75],
    ],
    properties: {
      type: "div",
      blind: [1],
      print: [1, 1, 2, 2, 0, 0, 0, 0, 0],
      copy: [1, 1, 0, 0, 0, 0, 0, 0, 0],
      screen: [1, 1, 1, 1, 0, 0, 0, 0, 0],
    },
  },

  /*** Qualitative ***/
  Set2: {
    3: [
      [102, 194, 165],
      [252, 141, 98],
      [141, 160, 203],
    ],
    4: [
      [102, 194, 165],
      [252, 141, 98],
      [141, 160, 203],
      [231, 138, 195],
    ],
    5: [
      [102, 194, 165],
      [252, 141, 98],
      [141, 160, 203],
      [231, 138, 195],
      [166, 216, 84],
    ],
    6: [
      [102, 194, 165],
      [252, 141, 98],
      [141, 160, 203],
      [231, 138, 195],
      [166, 216, 84],
      [255, 217, 47],
    ],
    7: [
      [102, 194, 165],
      [252, 141, 98],
      [141, 160, 203],
      [231, 138, 195],
      [166, 216, 84],
      [255, 217, 47],
      [229, 196, 148],
    ],
    8: [
      [102, 194, 165],
      [252, 141, 98],
      [141, 160, 203],
      [231, 138, 195],
      [166, 216, 84],
      [255, 217, 47],
      [229, 196, 148],
      [179, 179, 179],
    ],
    properties: {
      type: "qual",
      blind: [1, 2, 2, 2, 0, 0, 0],
      print: [1, 1, 1, 2, 2, 2],
      copy: [0],
      screen: [1, 1, 2, 2, 2, 2],
    },
  },
  Accent: {
    3: [
      [127, 201, 127],
      [190, 174, 212],
      [253, 192, 134],
    ],
    4: [
      [127, 201, 127],
      [190, 174, 212],
      [253, 192, 134],
      [255, 255, 153],
    ],
    5: [
      [127, 201, 127],
      [190, 174, 212],
      [253, 192, 134],
      [255, 255, 153],
      [56, 108, 176],
    ],
    6: [
      [127, 201, 127],
      [190, 174, 212],
      [253, 192, 134],
      [255, 255, 153],
      [56, 108, 176],
      [240, 2, 127],
    ],
    7: [
      [127, 201, 127],
      [190, 174, 212],
      [253, 192, 134],
      [255, 255, 153],
      [56, 108, 176],
      [240, 2, 127],
      [191, 91, 23],
    ],
    8: [
      [127, 201, 127],
      [190, 174, 212],
      [253, 192, 134],
      [255, 255, 153],
      [56, 108, 176],
      [240, 2, 127],
      [191, 91, 23],
      [102, 102, 102],
    ],
    properties: {
      type: "qual",
      blind: [2, 0, 0, 0, 0, 0, 0],
      print: [1, 1, 2, 2, 2, 2],
      copy: [0],
      screen: [1, 1, 1, 2, 2, 2],
    },
  },
  Set1: {
    3: [
      [228, 26, 28],
      [55, 126, 184],
      [77, 175, 74],
    ],
    4: [
      [228, 26, 28],
      [55, 126, 184],
      [77, 175, 74],
      [152, 78, 163],
    ],
    5: [
      [228, 26, 28],
      [55, 126, 184],
      [77, 175, 74],
      [152, 78, 163],
      [255, 127, 0],
    ],
    6: [
      [228, 26, 28],
      [55, 126, 184],
      [77, 175, 74],
      [152, 78, 163],
      [255, 127, 0],
      [255, 255, 51],
    ],
    7: [
      [228, 26, 28],
      [55, 126, 184],
      [77, 175, 74],
      [152, 78, 163],
      [255, 127, 0],
      [255, 255, 51],
      [166, 86, 40],
    ],
    8: [
      [228, 26, 28],
      [55, 126, 184],
      [77, 175, 74],
      [152, 78, 163],
      [255, 127, 0],
      [255, 255, 51],
      [166, 86, 40],
      [247, 129, 191],
    ],
    9: [
      [228, 26, 28],
      [55, 126, 184],
      [77, 175, 74],
      [152, 78, 163],
      [255, 127, 0],
      [255, 255, 51],
      [166, 86, 40],
      [247, 129, 191],
      [153, 153, 153],
    ],
    properties: {
      type: "qual",
      blind: [2],
      print: [1],
      copy: [0],
      screen: [1],
    },
  },
  Set3: {
    3: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
    ],
    4: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
    ],
    5: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
      [128, 177, 211],
    ],
    6: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
      [128, 177, 211],
      [253, 180, 98],
    ],
    7: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
      [128, 177, 211],
      [253, 180, 98],
      [179, 222, 105],
    ],
    8: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
      [128, 177, 211],
      [253, 180, 98],
      [179, 222, 105],
      [252, 205, 229],
    ],
    9: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
      [128, 177, 211],
      [253, 180, 98],
      [179, 222, 105],
      [252, 205, 229],
      [217, 217, 217],
    ],
    10: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
      [128, 177, 211],
      [253, 180, 98],
      [179, 222, 105],
      [252, 205, 229],
      [217, 217, 217],
      [188, 128, 189],
    ],
    11: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
      [128, 177, 211],
      [253, 180, 98],
      [179, 222, 105],
      [252, 205, 229],
      [217, 217, 217],
      [188, 128, 189],
      [204, 235, 197],
    ],
    12: [
      [141, 211, 199],
      [255, 255, 179],
      [190, 186, 218],
      [251, 128, 114],
      [128, 177, 211],
      [253, 180, 98],
      [179, 222, 105],
      [252, 205, 229],
      [217, 217, 217],
      [188, 128, 189],
      [204, 235, 197],
      [255, 237, 111],
    ],
    properties: {
      type: "qual",
      blind: [2, 2, 0, 0, 0, 0, 0, 0, 0, 0],
      print: [1, 1, 1, 1, 1, 1, 2, 0, 0, 0],
      copy: [1, 2, 2, 2, 2, 2, 2, 0, 0, 0],
      screen: [1, 1, 1, 2, 2, 2, 0, 0, 0, 0],
    },
  },
  Dark2: {
    3: [
      [27, 158, 119],
      [217, 95, 2],
      [117, 112, 179],
    ],
    4: [
      [27, 158, 119],
      [217, 95, 2],
      [117, 112, 179],
      [231, 41, 138],
    ],
    5: [
      [27, 158, 119],
      [217, 95, 2],
      [117, 112, 179],
      [231, 41, 138],
      [102, 166, 30],
    ],
    6: [
      [27, 158, 119],
      [217, 95, 2],
      [117, 112, 179],
      [231, 41, 138],
      [102, 166, 30],
      [230, 171, 2],
    ],
    7: [
      [27, 158, 119],
      [217, 95, 2],
      [117, 112, 179],
      [231, 41, 138],
      [102, 166, 30],
      [230, 171, 2],
      [166, 118, 29],
    ],
    8: [
      [27, 158, 119],
      [217, 95, 2],
      [117, 112, 179],
      [231, 41, 138],
      [102, 166, 30],
      [230, 171, 2],
      [166, 118, 29],
      [102, 102, 102],
    ],
    properties: {
      type: "qual",
      blind: [1, 2, 2, 2, 0, 0],
      print: [1],
      copy: [0],
      screen: [1],
    },
  },
  Paired: {
    3: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
    ],
    4: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
    ],
    5: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
      [251, 154, 153],
    ],
    6: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
      [251, 154, 153],
      [227, 26, 28],
    ],
    7: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
      [251, 154, 153],
      [227, 26, 28],
      [253, 191, 111],
    ],
    8: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
      [251, 154, 153],
      [227, 26, 28],
      [253, 191, 111],
      [255, 127, 0],
    ],
    9: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
      [251, 154, 153],
      [227, 26, 28],
      [253, 191, 111],
      [255, 127, 0],
      [202, 178, 214],
    ],
    10: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
      [251, 154, 153],
      [227, 26, 28],
      [253, 191, 111],
      [255, 127, 0],
      [202, 178, 214],
      [106, 61, 154],
    ],
    11: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
      [251, 154, 153],
      [227, 26, 28],
      [253, 191, 111],
      [255, 127, 0],
      [202, 178, 214],
      [106, 61, 154],
      [255, 255, 153],
    ],
    12: [
      [166, 206, 227],
      [31, 120, 180],
      [178, 223, 138],
      [51, 160, 44],
      [251, 154, 153],
      [227, 26, 28],
      [253, 191, 111],
      [255, 127, 0],
      [202, 178, 214],
      [106, 61, 154],
      [255, 255, 153],
      [177, 89, 40],
    ],
    properties: {
      type: "qual",
      blind: [1, 1, 2, 2, 2, 2, 0, 0, 0],
      print: [1, 1, 1, 1, 1, 2, 2, 2, 2],
      copy: [0],
      screen: [1, 1, 1, 1, 1, 1, 1, 1, 2],
    },
  },
  Pastel2: {
    3: [
      [179, 226, 205],
      [253, 205, 172],
      [203, 213, 232],
    ],
    4: [
      [179, 226, 205],
      [253, 205, 172],
      [203, 213, 232],
      [244, 202, 228],
    ],
    5: [
      [179, 226, 205],
      [253, 205, 172],
      [203, 213, 232],
      [244, 202, 228],
      [230, 245, 201],
    ],
    6: [
      [179, 226, 205],
      [253, 205, 172],
      [203, 213, 232],
      [244, 202, 228],
      [230, 245, 201],
      [255, 242, 174],
    ],
    7: [
      [179, 226, 205],
      [253, 205, 172],
      [203, 213, 232],
      [244, 202, 228],
      [230, 245, 201],
      [255, 242, 174],
      [241, 226, 204],
    ],
    8: [
      [179, 226, 205],
      [253, 205, 172],
      [203, 213, 232],
      [244, 202, 228],
      [230, 245, 201],
      [255, 242, 174],
      [241, 226, 204],
      [204, 204, 204],
    ],
    properties: {
      type: "qual",
      blind: [2, 0, 0, 0, 0, 0],
      print: [2, 0, 0, 0, 0, 0],
      copy: [0],
      screen: [2, 2, 0, 0, 0, 0],
    },
  },
  Pastel1: {
    3: [
      [251, 180, 174],
      [179, 205, 227],
      [204, 235, 197],
    ],
    4: [
      [251, 180, 174],
      [179, 205, 227],
      [204, 235, 197],
      [222, 203, 228],
    ],
    5: [
      [251, 180, 174],
      [179, 205, 227],
      [204, 235, 197],
      [222, 203, 228],
      [254, 217, 166],
    ],
    6: [
      [251, 180, 174],
      [179, 205, 227],
      [204, 235, 197],
      [222, 203, 228],
      [254, 217, 166],
      [255, 255, 204],
    ],
    7: [
      [251, 180, 174],
      [179, 205, 227],
      [204, 235, 197],
      [222, 203, 228],
      [254, 217, 166],
      [255, 255, 204],
      [229, 216, 189],
    ],
    8: [
      [251, 180, 174],
      [179, 205, 227],
      [204, 235, 197],
      [222, 203, 228],
      [254, 217, 166],
      [255, 255, 204],
      [229, 216, 189],
      [253, 218, 236],
    ],
    9: [
      [251, 180, 174],
      [179, 205, 227],
      [204, 235, 197],
      [222, 203, 228],
      [254, 217, 166],
      [255, 255, 204],
      [229, 216, 189],
      [253, 218, 236],
      [242, 242, 242],
    ],
    properties: {
      type: "qual",
      blind: [2, 0, 0, 0, 0, 0, 0],
      print: [2, 2, 2, 0, 0, 0, 0],
      copy: [0],
      screen: [2, 2, 2, 2, 0, 0, 0],
    },
  },

  /*** Sequential ***/
  OrRd: {
    3: [
      [254, 232, 200],
      [253, 187, 132],
      [227, 74, 51],
    ],
    4: [
      [254, 240, 217],
      [253, 204, 138],
      [252, 141, 89],
      [215, 48, 31],
    ],
    5: [
      [254, 240, 217],
      [253, 204, 138],
      [252, 141, 89],
      [227, 74, 51],
      [179, 0, 0],
    ],
    6: [
      [254, 240, 217],
      [253, 212, 158],
      [253, 187, 132],
      [252, 141, 89],
      [227, 74, 51],
      [179, 0, 0],
    ],
    7: [
      [254, 240, 217],
      [253, 212, 158],
      [253, 187, 132],
      [252, 141, 89],
      [239, 101, 72],
      [215, 48, 31],
      [153, 0, 0],
    ],
    8: [
      [255, 247, 236],
      [254, 232, 200],
      [253, 212, 158],
      [253, 187, 132],
      [252, 141, 89],
      [239, 101, 72],
      [215, 48, 31],
      [153, 0, 0],
    ],
    9: [
      [255, 247, 236],
      [254, 232, 200],
      [253, 212, 158],
      [253, 187, 132],
      [252, 141, 89],
      [239, 101, 72],
      [215, 48, 31],
      [179, 0, 0],
      [127, 0, 0],
    ],
  },
  PuBu: {
    3: [
      [236, 231, 242],
      [166, 189, 219],
      [43, 140, 190],
    ],
    4: [
      [241, 238, 246],
      [189, 201, 225],
      [116, 169, 207],
      [5, 112, 176],
    ],
    5: [
      [241, 238, 246],
      [189, 201, 225],
      [116, 169, 207],
      [43, 140, 190],
      [4, 90, 141],
    ],
    6: [
      [241, 238, 246],
      [208, 209, 230],
      [166, 189, 219],
      [116, 169, 207],
      [43, 140, 190],
      [4, 90, 141],
    ],
    7: [
      [241, 238, 246],
      [208, 209, 230],
      [166, 189, 219],
      [116, 169, 207],
      [54, 144, 192],
      [5, 112, 176],
      [3, 78, 123],
    ],
    8: [
      [255, 247, 251],
      [236, 231, 242],
      [208, 209, 230],
      [166, 189, 219],
      [116, 169, 207],
      [54, 144, 192],
      [5, 112, 176],
      [3, 78, 123],
    ],
    9: [
      [255, 247, 251],
      [236, 231, 242],
      [208, 209, 230],
      [166, 189, 219],
      [116, 169, 207],
      [54, 144, 192],
      [5, 112, 176],
      [4, 90, 141],
      [2, 56, 88],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 2, 2, 0, 0, 0, 0],
      copy: [1, 2, 0, 0, 0, 0, 0],
      screen: [1, 1, 2, 0, 0, 0, 0],
    },
  },
  BuPu: {
    3: [
      [224, 236, 244],
      [158, 188, 218],
      [136, 86, 167],
    ],
    4: [
      [237, 248, 251],
      [179, 205, 227],
      [140, 150, 198],
      [136, 65, 157],
    ],
    5: [
      [237, 248, 251],
      [179, 205, 227],
      [140, 150, 198],
      [136, 86, 167],
      [129, 15, 124],
    ],
    6: [
      [237, 248, 251],
      [191, 211, 230],
      [158, 188, 218],
      [140, 150, 198],
      [136, 86, 167],
      [129, 15, 124],
    ],
    7: [
      [237, 248, 251],
      [191, 211, 230],
      [158, 188, 218],
      [140, 150, 198],
      [140, 107, 177],
      [136, 65, 157],
      [110, 1, 107],
    ],
    8: [
      [247, 252, 253],
      [224, 236, 244],
      [191, 211, 230],
      [158, 188, 218],
      [140, 150, 198],
      [140, 107, 177],
      [136, 65, 157],
      [110, 1, 107],
    ],
    9: [
      [247, 252, 253],
      [224, 236, 244],
      [191, 211, 230],
      [158, 188, 218],
      [140, 150, 198],
      [140, 107, 177],
      [136, 65, 157],
      [129, 15, 124],
      [77, 0, 75],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 1, 2, 2, 0, 0, 0],
      copy: [1, 2, 0, 0, 0, 0, 0],
      screen: [1, 1, 1, 0, 0, 0, 0],
    },
  },
  Oranges: {
    3: [
      [254, 230, 206],
      [253, 174, 107],
      [230, 85, 13],
    ],
    4: [
      [254, 237, 222],
      [253, 190, 133],
      [253, 141, 60],
      [217, 71, 1],
    ],
    5: [
      [254, 237, 222],
      [253, 190, 133],
      [253, 141, 60],
      [230, 85, 13],
      [166, 54, 3],
    ],
    6: [
      [254, 237, 222],
      [253, 208, 162],
      [253, 174, 107],
      [253, 141, 60],
      [230, 85, 13],
      [166, 54, 3],
    ],
    7: [
      [254, 237, 222],
      [253, 208, 162],
      [253, 174, 107],
      [253, 141, 60],
      [241, 105, 19],
      [217, 72, 1],
      [140, 45, 4],
    ],
    8: [
      [255, 245, 235],
      [254, 230, 206],
      [253, 208, 162],
      [253, 174, 107],
      [253, 141, 60],
      [241, 105, 19],
      [217, 72, 1],
      [140, 45, 4],
    ],
    9: [
      [255, 245, 235],
      [254, 230, 206],
      [253, 208, 162],
      [253, 174, 107],
      [253, 141, 60],
      [241, 105, 19],
      [217, 72, 1],
      [166, 54, 3],
      [127, 39, 4],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 2, 0, 0, 0, 0, 0],
      copy: [1, 2, 2, 0, 0, 0, 0],
      screen: [1, 1, 1, 0, 0, 0, 0],
    },
  },
  BuGn: {
    3: [
      [229, 245, 249],
      [153, 216, 201],
      [44, 162, 95],
    ],
    4: [
      [237, 248, 251],
      [178, 226, 226],
      [102, 194, 164],
      [35, 139, 69],
    ],
    5: [
      [237, 248, 251],
      [178, 226, 226],
      [102, 194, 164],
      [44, 162, 95],
      [0, 109, 44],
    ],
    6: [
      [237, 248, 251],
      [204, 236, 230],
      [153, 216, 201],
      [102, 194, 164],
      [44, 162, 95],
      [0, 109, 44],
    ],
    7: [
      [237, 248, 251],
      [204, 236, 230],
      [153, 216, 201],
      [102, 194, 164],
      [65, 174, 118],
      [35, 139, 69],
      [0, 88, 36],
    ],
    8: [
      [247, 252, 253],
      [229, 245, 249],
      [204, 236, 230],
      [153, 216, 201],
      [102, 194, 164],
      [65, 174, 118],
      [35, 139, 69],
      [0, 88, 36],
    ],
    9: [
      [247, 252, 253],
      [229, 245, 249],
      [204, 236, 230],
      [153, 216, 201],
      [102, 194, 164],
      [65, 174, 118],
      [35, 139, 69],
      [0, 109, 44],
      [0, 68, 27],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 1, 2, 0, 0, 0, 0],
      copy: [1, 2, 0, 0, 0, 0, 0],
      screen: [1, 2, 0, 0, 0, 0, 0],
    },
  },
  YlOrBr: {
    3: [
      [255, 247, 188],
      [254, 196, 79],
      [217, 95, 14],
    ],
    4: [
      [255, 255, 212],
      [254, 217, 142],
      [254, 153, 41],
      [204, 76, 2],
    ],
    5: [
      [255, 255, 212],
      [254, 217, 142],
      [254, 153, 41],
      [217, 95, 14],
      [153, 52, 4],
    ],
    6: [
      [255, 255, 212],
      [254, 227, 145],
      [254, 196, 79],
      [254, 153, 41],
      [217, 95, 14],
      [153, 52, 4],
    ],
    7: [
      [255, 255, 212],
      [254, 227, 145],
      [254, 196, 79],
      [254, 153, 41],
      [236, 112, 20],
      [204, 76, 2],
      [140, 45, 4],
    ],
    8: [
      [255, 255, 229],
      [255, 247, 188],
      [254, 227, 145],
      [254, 196, 79],
      [254, 153, 41],
      [236, 112, 20],
      [204, 76, 2],
      [140, 45, 4],
    ],
    9: [
      [255, 255, 229],
      [255, 247, 188],
      [254, 227, 145],
      [254, 196, 79],
      [254, 153, 41],
      [236, 112, 20],
      [204, 76, 2],
      [153, 52, 4],
      [102, 37, 6],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 1, 2, 0, 0, 0, 0],
      copy: [1, 2, 2, 0, 0, 0, 0],
      screen: [1, 2, 0, 0, 0, 0, 0],
    },
  },
  YlGn: {
    3: [
      [247, 252, 185],
      [173, 221, 142],
      [49, 163, 84],
    ],
    4: [
      [255, 255, 204],
      [194, 230, 153],
      [120, 198, 121],
      [35, 132, 67],
    ],
    5: [
      [255, 255, 204],
      [194, 230, 153],
      [120, 198, 121],
      [49, 163, 84],
      [0, 104, 55],
    ],
    6: [
      [255, 255, 204],
      [217, 240, 163],
      [173, 221, 142],
      [120, 198, 121],
      [49, 163, 84],
      [0, 104, 55],
    ],
    7: [
      [255, 255, 204],
      [217, 240, 163],
      [173, 221, 142],
      [120, 198, 121],
      [65, 171, 93],
      [35, 132, 67],
      [0, 90, 50],
    ],
    8: [
      [255, 255, 229],
      [247, 252, 185],
      [217, 240, 163],
      [173, 221, 142],
      [120, 198, 121],
      [65, 171, 93],
      [35, 132, 67],
      [0, 90, 50],
    ],
    9: [
      [255, 255, 229],
      [247, 252, 185],
      [217, 240, 163],
      [173, 221, 142],
      [120, 198, 121],
      [65, 171, 93],
      [35, 132, 67],
      [0, 104, 55],
      [0, 69, 41],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 1, 1, 0, 0, 0, 0],
      copy: [1, 2, 0, 0, 0, 0, 0],
      screen: [1, 1, 1, 0, 0, 0, 0],
    },
  },
  Reds: {
    3: [
      [254, 224, 210],
      [252, 146, 114],
      [222, 45, 38],
    ],
    4: [
      [254, 229, 217],
      [252, 174, 145],
      [251, 106, 74],
      [203, 24, 29],
    ],
    5: [
      [254, 229, 217],
      [252, 174, 145],
      [251, 106, 74],
      [222, 45, 38],
      [165, 15, 21],
    ],
    6: [
      [254, 229, 217],
      [252, 187, 161],
      [252, 146, 114],
      [251, 106, 74],
      [222, 45, 38],
      [165, 15, 21],
    ],
    7: [
      [254, 229, 217],
      [252, 187, 161],
      [252, 146, 114],
      [251, 106, 74],
      [239, 59, 44],
      [203, 24, 29],
      [153, 0, 13],
    ],
    8: [
      [255, 245, 240],
      [254, 224, 210],
      [252, 187, 161],
      [252, 146, 114],
      [251, 106, 74],
      [239, 59, 44],
      [203, 24, 29],
      [153, 0, 13],
    ],
    9: [
      [255, 245, 240],
      [254, 224, 210],
      [252, 187, 161],
      [252, 146, 114],
      [251, 106, 74],
      [239, 59, 44],
      [203, 24, 29],
      [165, 15, 21],
      [103, 0, 13],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 2, 2, 0, 0, 0, 0],
      copy: [1, 2, 0, 0, 0, 0, 0],
      screen: [1, 2, 0, 0, 0, 0, 0],
    },
  },
  RdPu: {
    3: [
      [253, 224, 221],
      [250, 159, 181],
      [197, 27, 138],
    ],
    4: [
      [254, 235, 226],
      [251, 180, 185],
      [247, 104, 161],
      [174, 1, 126],
    ],
    5: [
      [254, 235, 226],
      [251, 180, 185],
      [247, 104, 161],
      [197, 27, 138],
      [122, 1, 119],
    ],
    6: [
      [254, 235, 226],
      [252, 197, 192],
      [250, 159, 181],
      [247, 104, 161],
      [197, 27, 138],
      [122, 1, 119],
    ],
    7: [
      [254, 235, 226],
      [252, 197, 192],
      [250, 159, 181],
      [247, 104, 161],
      [221, 52, 151],
      [174, 1, 126],
      [122, 1, 119],
    ],
    8: [
      [255, 247, 243],
      [253, 224, 221],
      [252, 197, 192],
      [250, 159, 181],
      [247, 104, 161],
      [221, 52, 151],
      [174, 1, 126],
      [122, 1, 119],
    ],
    9: [
      [255, 247, 243],
      [253, 224, 221],
      [252, 197, 192],
      [250, 159, 181],
      [247, 104, 161],
      [221, 52, 151],
      [174, 1, 126],
      [122, 1, 119],
      [73, 0, 106],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 1, 1, 2, 0, 0, 0],
      copy: [1, 2, 0, 0, 0, 0, 0],
      screen: [1, 1, 1, 0, 0, 0, 0],
    },
  },
  Greens: {
    3: [
      [229, 245, 224],
      [161, 217, 155],
      [49, 163, 84],
    ],
    4: [
      [237, 248, 233],
      [186, 228, 179],
      [116, 196, 118],
      [35, 139, 69],
    ],
    5: [
      [237, 248, 233],
      [186, 228, 179],
      [116, 196, 118],
      [49, 163, 84],
      [0, 109, 44],
    ],
    6: [
      [237, 248, 233],
      [199, 233, 192],
      [161, 217, 155],
      [116, 196, 118],
      [49, 163, 84],
      [0, 109, 44],
    ],
    7: [
      [237, 248, 233],
      [199, 233, 192],
      [161, 217, 155],
      [116, 196, 118],
      [65, 171, 93],
      [35, 139, 69],
      [0, 90, 50],
    ],
    8: [
      [247, 252, 245],
      [229, 245, 224],
      [199, 233, 192],
      [161, 217, 155],
      [116, 196, 118],
      [65, 171, 93],
      [35, 139, 69],
      [0, 90, 50],
    ],
    9: [
      [247, 252, 245],
      [229, 245, 224],
      [199, 233, 192],
      [161, 217, 155],
      [116, 196, 118],
      [65, 171, 93],
      [35, 139, 69],
      [0, 109, 44],
      [0, 68, 27],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 0, 0, 0, 0, 0, 0],
      copy: [1, 2, 0, 0, 0, 0, 0],
      screen: [1, 2, 0, 0, 0, 0, 0],
    },
  },
  YlGnBu: {
    3: [
      [237, 248, 177],
      [127, 205, 187],
      [44, 127, 184],
    ],
    4: [
      [255, 255, 204],
      [161, 218, 180],
      [65, 182, 196],
      [34, 94, 168],
    ],
    5: [
      [255, 255, 204],
      [161, 218, 180],
      [65, 182, 196],
      [44, 127, 184],
      [37, 52, 148],
    ],
    6: [
      [255, 255, 204],
      [199, 233, 180],
      [127, 205, 187],
      [65, 182, 196],
      [44, 127, 184],
      [37, 52, 148],
    ],
    7: [
      [255, 255, 204],
      [199, 233, 180],
      [127, 205, 187],
      [65, 182, 196],
      [29, 145, 192],
      [34, 94, 168],
      [12, 44, 132],
    ],
    8: [
      [255, 255, 217],
      [237, 248, 177],
      [199, 233, 180],
      [127, 205, 187],
      [65, 182, 196],
      [29, 145, 192],
      [34, 94, 168],
      [12, 44, 132],
    ],
    9: [
      [255, 255, 217],
      [237, 248, 177],
      [199, 233, 180],
      [127, 205, 187],
      [65, 182, 196],
      [29, 145, 192],
      [34, 94, 168],
      [37, 52, 148],
      [8, 29, 88],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 1, 1, 2, 2, 2, 0],
      copy: [1, 2, 0, 0, 0, 0, 0],
      screen: [1, 1, 2, 0, 0, 0, 0],
    },
  },
  Purples: {
    3: [
      [239, 237, 245],
      [188, 189, 220],
      [117, 107, 177],
    ],
    4: [
      [242, 240, 247],
      [203, 201, 226],
      [158, 154, 200],
      [106, 81, 163],
    ],
    5: [
      [242, 240, 247],
      [203, 201, 226],
      [158, 154, 200],
      [117, 107, 177],
      [84, 39, 143],
    ],
    6: [
      [242, 240, 247],
      [218, 218, 235],
      [188, 189, 220],
      [158, 154, 200],
      [117, 107, 177],
      [84, 39, 143],
    ],
    7: [
      [242, 240, 247],
      [218, 218, 235],
      [188, 189, 220],
      [158, 154, 200],
      [128, 125, 186],
      [106, 81, 163],
      [74, 20, 134],
    ],
    8: [
      [252, 251, 253],
      [239, 237, 245],
      [218, 218, 235],
      [188, 189, 220],
      [158, 154, 200],
      [128, 125, 186],
      [106, 81, 163],
      [74, 20, 134],
    ],
    9: [
      [252, 251, 253],
      [239, 237, 245],
      [218, 218, 235],
      [188, 189, 220],
      [158, 154, 200],
      [128, 125, 186],
      [106, 81, 163],
      [84, 39, 143],
      [63, 0, 125],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 0, 0, 0, 0, 0, 0],
      copy: [1, 2, 0, 0, 0, 0, 0],
      screen: [1, 0, 0, 0, 0, 0, 0],
    },
  },
  GnBu: {
    3: [
      [224, 243, 219],
      [168, 221, 181],
      [67, 162, 202],
    ],
    4: [
      [240, 249, 232],
      [186, 228, 188],
      [123, 204, 196],
      [43, 140, 190],
    ],
    5: [
      [240, 249, 232],
      [186, 228, 188],
      [123, 204, 196],
      [67, 162, 202],
      [8, 104, 172],
    ],
    6: [
      [240, 249, 232],
      [204, 235, 197],
      [168, 221, 181],
      [123, 204, 196],
      [67, 162, 202],
      [8, 104, 172],
    ],
    7: [
      [240, 249, 232],
      [204, 235, 197],
      [168, 221, 181],
      [123, 204, 196],
      [78, 179, 211],
      [43, 140, 190],
      [8, 88, 158],
    ],
    8: [
      [247, 252, 240],
      [224, 243, 219],
      [204, 235, 197],
      [168, 221, 181],
      [123, 204, 196],
      [78, 179, 211],
      [43, 140, 190],
      [8, 88, 158],
    ],
    9: [
      [247, 252, 240],
      [224, 243, 219],
      [204, 235, 197],
      [168, 221, 181],
      [123, 204, 196],
      [78, 179, 211],
      [43, 140, 190],
      [8, 104, 172],
      [8, 64, 129],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 1, 1, 2, 2, 2, 0],
      copy: [1, 2, 0, 0, 0, 0, 0],
      screen: [1, 1, 2, 0, 0, 0, 0],
    },
  },
  Greys: {
    3: [
      [240, 240, 240],
      [189, 189, 189],
      [99, 99, 99],
    ],
    4: [
      [247, 247, 247],
      [204, 204, 204],
      [150, 150, 150],
      [82, 82, 82],
    ],
    5: [
      [247, 247, 247],
      [204, 204, 204],
      [150, 150, 150],
      [99, 99, 99],
      [37, 37, 37],
    ],
    6: [
      [247, 247, 247],
      [217, 217, 217],
      [189, 189, 189],
      [150, 150, 150],
      [99, 99, 99],
      [37, 37, 37],
    ],
    7: [
      [247, 247, 247],
      [217, 217, 217],
      [189, 189, 189],
      [150, 150, 150],
      [115, 115, 115],
      [82, 82, 82],
      [37, 37, 37],
    ],
    8: [
      [255, 255, 255],
      [240, 240, 240],
      [217, 217, 217],
      [189, 189, 189],
      [150, 150, 150],
      [115, 115, 115],
      [82, 82, 82],
      [37, 37, 37],
    ],
    9: [
      [255, 255, 255],
      [240, 240, 240],
      [217, 217, 217],
      [189, 189, 189],
      [150, 150, 150],
      [115, 115, 115],
      [82, 82, 82],
      [37, 37, 37],
      [0, 0, 0],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 1, 2, 0, 0, 0, 0],
      copy: [1, 0, 0, 0, 0, 0, 0],
      screen: [1, 2, 0, 0, 0, 0, 0],
    },
  },
  YlOrRd: {
    3: [
      [255, 237, 160],
      [254, 178, 76],
      [240, 59, 32],
    ],
    4: [
      [255, 255, 178],
      [254, 204, 92],
      [253, 141, 60],
      [227, 26, 28],
    ],
    5: [
      [255, 255, 178],
      [254, 204, 92],
      [253, 141, 60],
      [240, 59, 32],
      [189, 0, 38],
    ],
    6: [
      [255, 255, 178],
      [254, 217, 118],
      [254, 178, 76],
      [253, 141, 60],
      [240, 59, 32],
      [189, 0, 38],
    ],
    7: [
      [255, 255, 178],
      [254, 217, 118],
      [254, 178, 76],
      [253, 141, 60],
      [252, 78, 42],
      [227, 26, 28],
      [177, 0, 38],
    ],
    8: [
      [255, 255, 204],
      [255, 237, 160],
      [254, 217, 118],
      [254, 178, 76],
      [253, 141, 60],
      [252, 78, 42],
      [227, 26, 28],
      [177, 0, 38],
    ],
    9: [
      [255, 255, 204],
      [255, 237, 160],
      [254, 217, 118],
      [254, 178, 76],
      [253, 141, 60],
      [252, 78, 42],
      [227, 26, 28],
      [189, 0, 38],
      [128, 0, 38],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 1, 2, 2, 0, 0, 0],
      copy: [1, 2, 2, 0, 0, 0, 0],
      screen: [1, 2, 2, 0, 0, 0, 0],
    },
  },
  PuRd: {
    3: [
      [231, 225, 239],
      [201, 148, 199],
      [221, 28, 119],
    ],
    4: [
      [241, 238, 246],
      [215, 181, 216],
      [223, 101, 176],
      [206, 18, 86],
    ],
    5: [
      [241, 238, 246],
      [215, 181, 216],
      [223, 101, 176],
      [221, 28, 119],
      [152, 0, 67],
    ],
    6: [
      [241, 238, 246],
      [212, 185, 218],
      [201, 148, 199],
      [223, 101, 176],
      [221, 28, 119],
      [152, 0, 67],
    ],
    7: [
      [241, 238, 246],
      [212, 185, 218],
      [201, 148, 199],
      [223, 101, 176],
      [231, 41, 138],
      [206, 18, 86],
      [145, 0, 63],
    ],
    8: [
      [247, 244, 249],
      [231, 225, 239],
      [212, 185, 218],
      [201, 148, 199],
      [223, 101, 176],
      [231, 41, 138],
      [206, 18, 86],
      [145, 0, 63],
    ],
    9: [
      [247, 244, 249],
      [231, 225, 239],
      [212, 185, 218],
      [201, 148, 199],
      [223, 101, 176],
      [231, 41, 138],
      [206, 18, 86],
      [152, 0, 67],
      [103, 0, 31],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 1, 1, 0, 0, 0, 0],
      copy: [1, 2, 0, 0, 0, 0, 0],
      screen: [1, 1, 1, 0, 0, 0, 0],
    },
  },
  Blues: {
    3: [
      [222, 235, 247],
      [158, 202, 225],
      [49, 130, 189],
    ],
    4: [
      [239, 243, 255],
      [189, 215, 231],
      [107, 174, 214],
      [33, 113, 181],
    ],
    5: [
      [239, 243, 255],
      [189, 215, 231],
      [107, 174, 214],
      [49, 130, 189],
      [8, 81, 156],
    ],
    6: [
      [239, 243, 255],
      [198, 219, 239],
      [158, 202, 225],
      [107, 174, 214],
      [49, 130, 189],
      [8, 81, 156],
    ],
    7: [
      [239, 243, 255],
      [198, 219, 239],
      [158, 202, 225],
      [107, 174, 214],
      [66, 146, 198],
      [33, 113, 181],
      [8, 69, 148],
    ],
    8: [
      [247, 251, 255],
      [222, 235, 247],
      [198, 219, 239],
      [158, 202, 225],
      [107, 174, 214],
      [66, 146, 198],
      [33, 113, 181],
      [8, 69, 148],
    ],
    9: [
      [247, 251, 255],
      [222, 235, 247],
      [198, 219, 239],
      [158, 202, 225],
      [107, 174, 214],
      [66, 146, 198],
      [33, 113, 181],
      [8, 81, 156],
      [8, 48, 107],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 2, 0, 0, 0, 0, 0],
      copy: [1, 0, 0, 0, 0, 0, 0],
      screen: [1, 2, 0, 0, 0, 0, 0],
    },
  },
  PuBuGn: {
    3: [
      [236, 226, 240],
      [166, 189, 219],
      [28, 144, 153],
    ],
    4: [
      [246, 239, 247],
      [189, 201, 225],
      [103, 169, 207],
      [2, 129, 138],
    ],
    5: [
      [246, 239, 247],
      [189, 201, 225],
      [103, 169, 207],
      [28, 144, 153],
      [1, 108, 89],
    ],
    6: [
      [246, 239, 247],
      [208, 209, 230],
      [166, 189, 219],
      [103, 169, 207],
      [28, 144, 153],
      [1, 108, 89],
    ],
    7: [
      [246, 239, 247],
      [208, 209, 230],
      [166, 189, 219],
      [103, 169, 207],
      [54, 144, 192],
      [2, 129, 138],
      [1, 100, 80],
    ],
    8: [
      [255, 247, 251],
      [236, 226, 240],
      [208, 209, 230],
      [166, 189, 219],
      [103, 169, 207],
      [54, 144, 192],
      [2, 129, 138],
      [1, 100, 80],
    ],
    9: [
      [255, 247, 251],
      [236, 226, 240],
      [208, 209, 230],
      [166, 189, 219],
      [103, 169, 207],
      [54, 144, 192],
      [2, 129, 138],
      [1, 108, 89],
      [1, 70, 54],
    ],
    properties: {
      type: "seq",
      blind: [1],
      print: [1, 2, 2, 0, 0, 0, 0],
      copy: [1, 2, 0, 0, 0, 0, 0],
      screen: [1, 1, 2, 0, 0, 0, 0],
    },
  },
  schemeGroups: {
    diverging: [
      "BrBG",
      "PiYG",
      "PRGn",
      "PuOr",
      "RdBu",
      "RdGy",
      "RdYlBu",
      "RdYlGn",
      "Spectral",
    ],
    qualitative: [
      "Accent",
      "Dark2",
      "Paired",
      "Pastel1",
      "Pastel2",
      "Set1",
      "Set2",
      "Set3",
    ],
    sequential: [
      "BuGn",
      "BuPu",
      "GnBu",
      "OrRd",
      "PuBu",
      "PuBuGn",
      "PuRd",
      "RdPu",
      "YlGn",
      "YlGnBu",
      "YlOrBr",
      "YlOrRd",
    ],
    singlehue: ["Blues", "Greens", "Greys", "Oranges", "Purples", "Reds"],
  },
};

export const geoda = {
  lisa: [
    [255, 255, 255],
    [255, 0, 0],
    [0, 0, 255],
    [167, 173, 249],
    [244, 173, 168],
    [70, 70, 70],
    [153, 153, 153],
  ],
  hinge_breaks: [
    [1, 102, 94],
    [90, 180, 172],
    [199, 234, 229],
    [246, 232, 195],
    [216, 179, 101],
    [140, 81, 10],
  ],
};
